#installment::-webkit-scrollbar {
  height: 4px;
}

#installment::-webkit-scrollbar-track {
  width: 2px;
  background-color: #ececec;
}

#installment::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: rgb(121, 121, 121);
  border-radius: 20px;
}
