@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Open Sans", sans-serif;
}

.main-container {
  width: 85%;
  margin: auto;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.mainHeading {
  font-size: 25px;
  font-weight: 600;
  color: rgb(105, 105, 105);
  margin-right: 25px;
}

.custButton {
  background-color: rgb(0, 163, 238);
  padding: 8px 15px;
  border-radius: 5px;
  transition: all 300ms;
  color: white;
}

.custButton:hover {
  background-color: rgb(0, 127, 185);
}

.submitButton {
  background-color: gray;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  color: white;
  transition: all 300ms;
  font-size: 14px;
}

.submitButton:hover {
  background-color: #2d3446;
}

.submitButton:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
  border-color: #6366f1;
  background-color: #2d3446;
}

.table-padding {
  padding: 35px;
  overflow: auto;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  width: 1px;
  background-color: #d3d3d3;
}

body::-webkit-scrollbar-thumb {
  width: 1px;
  background-color: rgb(167, 167, 167);
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .main-container {
    width: 95%;
  }
  .p-placeholder,
  .p-inputtext {
    font-size: 14px !important;
  }

  .table-padding {
    padding: 25px 20px;
  }
}
