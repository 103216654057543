.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
  font-size: 14px;
}

.p-component,
.p-component * {
  box-sizing: border-box;
  font-size: 14px;
}

.faq-remove {
    background-color: rgb(243, 41, 41) !important;
    transition: all 300ms;
    cursor: pointer !important;
    color: white !important;
}

.faq-remove:hover {
    background-color: rgb(175, 12, 12) !important;
}
