:root {
  --primary-color: #1f2431;
  --secondary-color: #2d3446;
  --third-color: #3c455c;
}

nav {
  transition: all 300ms;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
}

.mainContainer {
  color: #fff;
  background-color: var(--secondary-color);
  /* min-height: 100vh; */
  height: 100vh;
  transition: all 300ms;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  overflow: auto;
}

.openSideBarWidth {
  width: 250px;
}

.closeSideBarWidth {
  width: 80px;
  min-width: 80px;
}

.closeSideBarTabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.topHead {
  background-color: #1f2431;
  letter-spacing: 3px;
  font-size: 22px;
  width: 100%;
  text-align: center;
}

.topHead1 {
  font-weight: bold;
}
.topHead2 {
  font-weight: 300;
}

.closeSidebar {
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0px;
  /* right: -15px; */
  right: 0px;
}

.closeSidebarIcon {
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 2px;
  display: none;
  cursor: pointer;
}

.liRowContainer {
  /* display: 'flex', color: 'rgb(120,129,149)', alignItems: 'baseline'  */
  display: flex;
  color: rgb(120, 129, 149);
  align-items: center;
  font-size: 15px;
  padding: 15px 30px;
  transition: all 300ms;
}

.liRowContainer:hover {
  color: #fff;
  background-color: var(--third-color);
  cursor: pointer;
}

.liSelectedRowContainer {
  color: #fff;
  background-color: var(--third-color);
}

.popup {
  margin-bottom: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  padding: 3px 15px;
  /* margin: 5px; */
}
.popup:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 870px) {
  .closeSideBarWidth {
    width: 0px;
    min-width: 0px;
    overflow: hidden;
  }
  .closeSidebarIcon {
    display: block;
  }
  .openSideBarWidth {
    position: fixed;
    height: 100%;
    z-index: 5;
  }
  .mainContainer {
    overflow-y: auto;
    /* overflow-x: hidden; */
  }

  .mainContainer::-webkit-scrollbar {
    width: 3px;
  }

  .mainContainer::-webkit-scrollbar-track {
    width: 2px;
    background-color: #fff;
  }

  .mainContainer::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: grey;
    border-radius: 20px;
  }
}


.mainContainer::-webkit-scrollbar {
  width: 0;
}

