
.social-fb {
    background: linear-gradient(#4166b1, #224285);
    transition: all 300ms;
  }
  .social-fb:hover {
    background: linear-gradient(to top, #4166b1, #224285);
  }
  
  .social-insta {
    transition: all 300ms;
    background: linear-gradient(225deg, #285aeb 10%, #c22aa7, #f34b5e, #fdb477);
  }
  
  .social-insta:hover {
    background: linear-gradient(0deg, #285aeb 10%, #c22aa7, #f34b5e, #fdb477);
  }
  
  .social-telegram {
    transition: all 300ms;
    background: linear-gradient(#2282ba, #3fafdf);
  }
  
  .social-telegram:hover {
    background: linear-gradient(to top, #2282ba, #3fafdf);
  }
  
  .social-youtube {
    transition: all 300ms;
    background: linear-gradient(#f14235, #651c05);
  }
  
  .social-youtube:hover {
    background: linear-gradient(#651c05, #f14235);
  }