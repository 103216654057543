:root {
  --first-l-color: #e9cad2;
  --first-d-color: #d6a5b2;
  --first-i-color: #d48398;
  --first-t-color: #ac556b;

  --second-l-color: #dab9da;
  --second-d-color: #e0c9e0;
  --second-i-color: #b180b1;
  --second-t-color: #6e3e6e;

  --third-l-color: #beb7e7;
  --third-d-color: #6558bb;
  --third-i-color: #796dc4;
  --third-t-color: #3b3178;

  --fourth-l-color: #b0bdf0;
  --fourth-d-color: #b5c1ee;
  --fourth-i-color: #5d74c9;
  --fourth-t-color: #28366e;

  --fifth-l-color: #b4cde2;
  --fifth-d-color: #7ca8cc;
  --fifth-i-color: #6aa5d4;
  --fifth-t-color: #4682b4;

  --sixth-l-color: #c3cfbc;
  --sixth-d-color: #95aa88;
  --sixth-i-color: #87a574;
  --sixth-t-color: #687e5a;

  --seventh-l-color: #f8e1a6;
  --seventh-d-color: #f3ca64;
  --seventh-i-color: #eeb422;
  --seventh-t-color: #eeb422;

  --first-e: #eae8fe;
  --first-l: #cfcbfe;
  --first: #3b32a7;

  --second-e: #fee2f4;
  --second-l: #fcb7de;
  --second: #fd3a9b;

  --third-e: #d1f7ff;
  --third-l: #adedff;
  --third: #29cfff;

  --fourth-e: #fdedcc;
  --fourth-l: #ffe1a5;
  --fourth: #ffaa00;

  --fifth-e: #f9d7d7;
  --fifth-l: #f5baba;
  --fifth: #eb0000;

  --stat-first-e: #f3c5d3;
  --stat-first-l: #e99bb2;
  --stat-first: #de5d83;

  --stat-second-e: #e6e6fa;
  --stat-second-l: #c9c9f5;
  --stat-second: #8f8ff1;

  --stat-third-e: #d6e9c5;
  --stat-third-l: #b5ce9e;
  --stat-third: #3d5229;

  --stat-fourth-e: #c7f5f3;
  --stat-fourth-l: #9cede9;
  --stat-fourth: #20b2aa;

  --stat-fifth-e: #c7e6d8;
  --stat-fifth-l: #9ac8b3;
  --stat-fifth: #213d30;

  --stat-sixth-e: #e0d5fc;
  --stat-sixth-l: #bba8eb;
  --stat-sixth: #6f59a1;
}

#dashboardMainConatainer {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  /* grid-template-rows: 400px; */
  grid-gap: 15px;
}

#statsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: 400px; */
  grid-gap: 15px;
}

.profileIcon {
  height: 50px;
  width: 50px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.positionContent {
  padding: 0px 5px;
  /* font-family: ""; */
  margin-bottom: 0px;
  text-transform: capitalize;
}

.wish {
  font-size: 1.75rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 5px;
  text-transform: capitalize;
}

.p-datepicker table td {
  padding: 0 !important;
}

#ongoingCourses {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  margin-top: 15px;
}

.stat1 {
  background-color: var(--stat-first-e);
  color: var(--stat-first);
}

.stat1-icon {
  background-color: var(--stat-first-l);
}

.stat2 {
  background-color: var(--stat-second-e);
  color: var(--stat-second);
}

.stat2-icon {
  background-color: var(--stat-second-l);
}

.stat3 {
  background-color: var(--stat-third-e);
  color: var(--stat-third);
}

.stat3-icon {
  background-color: var(--stat-third-l);
}

.stat4 {
  background-color: var(--stat-fourth-e);
  color: var(--stat-fourth);
}

.stat4-icon {
  background-color: var(--stat-fourth-l);
}

.stat5 {
  background-color: var(--stat-fifth-e);
  color: var(--stat-fifth);
}

.stat6-icon {
  background-color: var(--stat-sixth-l);
}

.stat6 {
  background-color: var(--stat-sixth-e);
  color: var(--stat-sixth);
}

.stat5-icon {
  background-color: var(--stat-fifth-l);
}

.tile1 {
  background-color: var(--first-e);
  color: var(--first);
}

.tile1-icon {
  background-color: var(--first-l);
}

.tile2 {
  background-color: var(--second-e);
  color: var(--second);
}

.tile2-icon {
  background-color: var(--second-l);
}

.tile3 {
  background-color: var(--third-e);
  color: var(--third);
}

.tile3-icon {
  background-color: var(--third-l);
}

.tile4 {
  background-color: var(--fourth-e);
  color: var(--fourth);
}

.tile4-icon {
  background-color: var(--fourth-l);
}

.tile5 {
  background-color: var(--fifth-e);
  color: var(--fifth);
}

.tile5-icon {
  background-color: var(--fifth-l);
}

.color-1 {
  background-color: var(--first-l-color);
  color: var(--first-t-color);
}

.color-2 {
  background-color: var(--second-l-color);
  color: var(--second-t-color);
}

.color-3 {
  background-color: var(--third-l-color);
  color: var(--third-t-color);
}

.color-4 {
  background-color: var(--fourth-l-color);
  color: var(--fourth-t-color);
}

.color-5 {
  background-color: var(--fifth-l-color);
  color: var(--fifth-t-color);
}

.color-6 {
  background-color: var(--sixth-l-color);
  color: var(--sixth-t-color);
}

.color-l-1 {
  background-color: var(--first-i-color);
  color: var(--first-l-color);
}

.color-l-2 {
  background-color: var(--second-i-color);
  color: var(--second-l-color);
}

.color-l-3 {
  background-color: var(--third-i-color);
  color: var(--third-l-color);
}

.color-l-4 {
  background-color: var(--fourth-i-color);
  color: var(--fourth-l-color);
}

.color-l-5 {
  background-color: var(--fifth-i-color);
  color: var(--fifth-l-color);
}

.color-l-6 {
  background-color: var(--sixth-i-color);
  color: var(--sixth-l-color);
}

.team-members {
  max-height: 300px;
}

.team-members::-webkit-scrollbar {
  width: 4px;
}

.team-members::-webkit-scrollbar-track {
  width: 1px;
  background-color: #d3d3d3;
}

.team-members::-webkit-scrollbar-thumb {
  width: 1px;
  background-color: rgb(167, 167, 167);
  border-radius: 20px;
}

@media only screen and (max-width: 714px) {
  .wish {
    font-size: 1.5rem;
  }
  .wish + div p {
    font-size: 15px;
  }
  .mainFrame {
    width: 90%;
  }
}
@media only screen and (max-width: 670px) {
  .wish {
    font-size: 1.25rem;
  }
  .wish + div p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .profileIcon {
    height: 50px;
    width: 50px;
  }
  .profileIcon > svg {
    height: 30px;
    width: 30px;
  }
}
